import React, { useState, useEffect,useRef } from 'react';
import Game from './Game';
import WinScreen from './WinScreen';
import CircularProgress from '@mui/material/CircularProgress';


function App() {
  const [isGameOver, setIsGameOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [promoCount, setPromoCount] = useState(0);
  const [giftMessage1, setGiftMessage1] = useState('');
  const [giftMessage2, setGiftMessage2] = useState('');
  const [giftTitle, setTitle] = useState('');
  const callAPI = useRef(false);
  const handleGameOver = () => {
    setIsLoading(true);
      fetch("https://campaign.unitel.mn/set/gift", {
        method: 'POST',
        headers: {
      'Content-Type': 'application/json'
        },
        body: JSON.stringify({ tokens: token })
    })
    .then((response) => response.json())
    .then((data) => {
      setGiftMessage1(data.gift_text);
      setGiftMessage2(data.gift_text_sub);
      setTitle(data.gift_title);
      console.log("res", JSON.stringify(data));
      setIsGameOver(true);
    })
    .catch((error) =>
      console.error("Error fetching snack count:", error)
    ).finally(() => {
      setIsLoading(false);
    });;
    
  };
  const params = new URLSearchParams(window.location.search);
  let token = params.get("tokenid");

  useEffect(() => {
   
    if (callAPI.current === false) {
      setIsLoading(true);
      fetch("https://campaign.unitel.mn/promo/entitle", {
          method: 'POST',
          headers: {
        'Content-Type': 'application/json'
          },
          body: JSON.stringify({ tokens: token })
      })
      .then((response) => response.json())
      .then((data) => {
        setPromoCount(data.message);
        console.log("res", JSON.stringify(data));
      })
      .catch((error) =>
        console.error("Error fetching snack count:", error)
      ).finally(() => {
        setIsLoading(false);
      });
    }
    callAPI.current = true;
  }, []);

  return (
    <div className="App">
        
    </div>
  );
}

export default App;
